.button {
    border: none;
    padding: .4em 1em;
    margin: auto;
    transition: 0.3s;
    font-size: 70%;
    font-weight: bold;
    border-radius: .3rem;
    width: 8em;
    text-align: center;
}

.button:hover{
    background:rgba(255,255,255,0.4);
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.button:focus{
    outline: none;
}


.threads{
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf49781 0%, #fdf49781 5%, #fd584981 45%,#d6249f81 60%,#285AEB81 90%);
    text-shadow: 0 0 20px #000;
    opacity: 1;
}