.blocks{
    width: min(2000px,80vw);
    margin: 10vh auto;
    display: flex;
    flex-wrap: wrap;
    font-size: clamp(1rem, 0.9167rem + 0.3333vw, 1.25rem);
}

.blocks > img{
    object-fit: cover;
    height: 25vh;
    width: 100%;
    object-position: 0 55%;
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1em;
    margin: 1em 0;
    flex: 2;
}

.buttons *{
    flex: 1;
}


.splashblock{
    background-color: #FFF600;
    border-radius: .5rem;
    padding: 1em;
    line-height: 1.25;
    font-size: 100%;
    font-weight: 400;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
    align-items: center;
}

.splashblock > a img{
    width: min(300px,60vw);
}

.splashblock > a:first-child{
    width: 100%;
}

.splashblock h1{
    text-align: center;
    font-weight: 800;
    width: 100%;
    line-height: 1.1;
    margin: 0 0 20px 0;
}

@media only screen and (width <= 1000px){
    .splashblock{
        gap: 1em;
        padding: 0;
    }

    .splashblock h1{
        hyphens: auto;
        margin: 10px 0;
    }

    .splashblock > a:first-child{
        margin: 1em;
    }
}

@media only screen and (min-width:1600px){
    .blocks{
        font-size: clamp(1.875rem, -3.125rem + 5vw, 3.125rem);

    }

}
