.blocks{
    width: min(2000px,80vw);
    margin: 10vh auto;
    display: flex;
    flex-wrap: wrap;
    font-size: clamp(1rem, 0.9167rem + 0.3333vw, 1.25rem);
}

.blocks > img{
    object-fit: cover;
    height: 25vh;
    width: 100%;
    object-position: 0 55%;
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1em;
    margin: 1em 0;
    flex: 1;
}

.buttons *{
    flex: 1;
}

.intro{
    width: 50%;
    padding: 40px;
}

.img{
    flex: 1;
}

.img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 60% 0;
    border-radius: 0 .5rem 0 0;
}

.splashblock{
    background-color: #FFF600;
    border-radius: .5rem .5rem 0 0;
    padding: 0;
    line-height: 1.25;
    font-size: 150%;
    display: flex;
    width: 100%;
}

.splashblock h1{
    font-weight: 800;
    line-height: 1.1;
    margin: 0 0 20px 0;
}

.block{
    padding: 40px;
    width: 100%;
}

.block h1{
    font-size: 220%;
    line-height: 1.2;
    margin: 0;
}

.aboutme{
    text-align: justify;
    line-height: 1.3;
    hyphens: auto;
}

.aboutme .buttons{
    font-size: 120%;
    margin-top: 2em;
    width: 33%;
}

.quotebox{
    border-radius: 0 0 .5rem .5rem;
    text-align: -webkit-center;
    place-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 80%;
}

.quotebox img{
    display: inline-block;
    height: 100%;
}

.quote{
    max-width: 500px;
    margin-left: 5rem;
    padding: 1rem;
    text-align: left;
}

.quote h2{
    font-weight: 400;
}

@media only screen and (max-width:1000px){
    .splashblock{
        flex-wrap: wrap;
    }

    .intro{
        width: 100%;
        text-align: center;
    }

    .img{
        height: 30vh;
    }

    .img img{
        border-radius: 0;
    }

    .img img{
        object-position: 60% 60%;
    }

    .blocks > img:nth-child(2){
        display: none;
    }

    .aboutme .buttons{
        width: 100%;
    }

    .quotebox img{
        height: auto;
    }

    .quote{
        text-align: center;
        margin: 2em 0;
        width: 100%;
    }
}

@media only screen and (min-width:1600px){
    .blocks{
        font-size: clamp(1.875rem, -3.125rem + 5vw, 3.125rem);

    }

}
